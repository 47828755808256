import '../scss/styles.scss';
import scroll from './ScrollTrigger.js';

document.addEventListener('DOMContentLoaded', function () {
    var trigger = new scroll();
  });

  (function() {
    let d = document;
  
    function init() {
      //Links 
      let anchor1Link  = d.getElementById('teamLink');
      let anchor2Link  = d.getElementById('techsLink');
      let anchor3Link  = d.getElementById('portfolioLink');
      let anchor4Link  = d.getElementById('contactLink');
      //Anchors
      let anchor1      = d.getElementById('team');
      let anchor2      = d.getElementById('techs');
      let anchor3      = d.getElementById('portfolio');
      let anchor4      = d.getElementById('contact');
      
      anchor1Link.addEventListener('click', (e) => { scrollTo(anchor1, e) }, false);
      anchor2Link.addEventListener('click', (e) => { scrollTo(anchor2, e) }, false);
      anchor3Link.addEventListener('click', (e) => { scrollTo(anchor3, e) }, false);
      anchor4Link.addEventListener('click', (e) => { scrollTo(anchor4, e) }, false);
    }
  
    function scrollTo(to, callback, duration = 1500) { 
      if (isDomElement(to)) {
        to = to.offsetTop;
      }
      function move(amount) {
        document.documentElement.scrollTop = amount;
        document.body.parentNode.scrollTop = amount;
        document.body.scrollTop = amount;
      }
  
      function position() {
        return document.documentElement.offsetTop || document.body.parentNode.offsetTop || document.body.offsetTop;
      }
      
      var start = position(),
        change = to - start,
        currentTime = 0,
        increment = 20;
      
      var animateScroll = function() {
        currentTime += increment;
        var val = Math.easeInOutQuad(currentTime, start, change, duration);
        move(val);
        if (currentTime < duration) {
          window.requestAnimationFrame(animateScroll);
        }
        else {
          if (callback && typeof(callback) === 'function') {
            callback();
          }
        }
      };
      
      animateScroll();
    }
  
    init();
  })();
  
  
  Math.easeInOutQuad = function(t, b, c, d) {
    t /= d / 2;
    if (t < 1) {
      return c / 2 * t * t + b
    }
    t--;
    return -c / 2 * (t * (t - 2) - 1) + b;
  };
  
  function isDomElement(obj) {
      return obj instanceof Element;
  }
